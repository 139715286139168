import styled, { css } from 'styled-components'
export const Row = styled.div`
    display: flex;
    ${({gridGap}) => gridGap && css`grid-gap: ${gridGap}`};
    ${({margin}) => margin && css`margin: ${margin}`};


    @media screen and (max-width: 768px) {
        ${({flexCWS}) => flexCWS && css`flex-direction: column !important;`}
    }
`

