import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const position = {
  lat: -23.497921654194794,
  lng: -46.849968488970696
};

let googleMapsApiKey = 'AIzaSyCyYIwYpE42g7zN-nVRrPKkAwRseyccpaM'

function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey
  })

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={19}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <>
            <Marker position={position}/>
        </>
      </GoogleMap>
  ) : <></>
}

export default React.memo(MyComponent)
