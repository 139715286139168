import styled, {css} from 'styled-components'
import { motion } from 'framer-motion'


export default function Card (props) {
    return <StyledCard mini={props.mini || false} 
        as={motion.div} 
        variants={{visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: [.6, .05, -.01, .9], type: "tween", stiffness: 80 }
        },
        hidden: {
            opacity: 0,
            y: '40%',
        }}}
        initial='hidden'
        whileInView={'visible'}
        viewport={{ once: false }}
        onClick={() => props.onClick()}
        >
        <div className="header">
            <div className="wrapper-icon">
                <img src={props.card.icon} alt={props.card.alt}/>
            </div>
            <img className="arrow" src='/svgs/arrow-right-small.svg' alt='arrow-right-icon'/>
        </div>
        <h1 className="title">{props.card.title}</h1>
        <p className="subtitle">{props.card.subtitle}</p>
    </StyledCard>
}

const StyledCard = styled.div`
    padding: 0 5% 2em 5%;
    border: 1px solid rgba(25, 18, 51, 0.12);
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    user-select: none;


    .header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;
    }

    .title{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #191233;
        margin-bottom: .5em;
    }

    .subtitle{
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.9375rem, 0.9196rem + 0.0893vw, 1rem);
        line-height: clamp(1.5rem, 1.4643rem + 0.1786vw, 1.625rem);    
        color: #595666;
    }

    .wrapper-icon{
        width: 5.5em;
        height: 6.5em;
        background-color: #01E6BE;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-top: 1em;
    }

    ${({mini}) => mini && css`
        .arrow {
            display: none !important;
        }

        .subtitle{
            display: none !important;
        }
        .header{
            width: unset !important;
        }
        .title{
            font-size: clamp(1.125rem, 1.0179rem + 0.5357vw, 1.5rem) !important;
            line-height: clamp(1.75rem, 1.6429rem + 0.5357vw, 2.125rem) !important;
        }

        .wrapper-icon{
            width: clamp(4.5rem, 4.2143rem + 1.4286vw, 5.5rem);
            height: clamp(4.5rem, 4.2143rem + 1.4286vw, 5.5rem);
            background-color: #01E6BE;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: unset;
        }
        width: fit-content !important;
        align-items: center !important;
        padding: unset !important;
        border: unset !important;
    `}

    &:hover{
        cursor: pointer;
    }
        
`