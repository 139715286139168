import Home from '../../sections/Home'
import About from '../../sections/About'
import Products from '../../sections/Products'
import AWS from '../../sections/Aws';
import Hybrid from '../../sections/Hybrid';
import { useEffect } from 'react';

export default function HomePage(){
    useEffect(() => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
        });

    }, [])
    return <>
        <Home />
        <About />
        <Products />
        <AWS />
        <Hybrid />
    </>
} 