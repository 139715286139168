import styled, { css } from 'styled-components'

export default function TextArea(props){
    return <TextAreaWrapper error={props.error} onClick={() => props.onClick()}>
        <label>{props.label}</label>
        <textarea name={props.name} form={props.form}/>
        <span className='error'>
            <p>{props.error && props.error}</p>
        </span>
    </TextAreaWrapper>
}

const TextAreaWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    user-select: none;
    position: relative;

    > label {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        margin-bottom: .5em;
    }
    > textarea {
        outline: none;
        width: 100%;
        min-height: 144px;
        resize: vertical;
        border: 1px solid #CACCCC;
        padding: 1.725em;
        transition: .2s all;

        &:focus{
            border: 1px solid #01E6BE;
        }

        ${({error}) => error && css`
            background: #FEF9F9 !important;
            border: 1px solid #F9566D !important;
        `}
    }
    span{
        position: absolute;
        bottom: -12%;
        transition: .2s all;
    }
    .error{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 10px;
        color: #CC3939;
    }
`