import { AnimatePresence } from 'framer-motion'
import React, { useState, useImperativeHandle, useEffect } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

const Select = React.forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState({})

    const handleSelect = (option) => {
        if((Object.keys(selected).length > 0) && (selected.title == option.title)){
            setSelected({})
        }else{
            setSelected(option)
        }
    }

    useImperativeHandle(ref, () => ({
                state: () => {return selected}, 
                clearState: () => {return setSelected({})},
                setState: (data) => {return setSelected(data)} 
            }), [selected]);
    
    
    useEffect(() => {
        props.onChange && props.onChange()
    }, [selected])

    if(props.hidden == true){
        return null
    }

    return <>
        <SelectWrapper 
            error={props.error} 
            onClick={() => {setVisible(!visible); props.onClick()}} 
            onMouseLeave={() => setVisible(false)}>
            <label>{props.label}</label>
            {!Object.keys(selected).length && 
            <div> 
                <p>Selecione</p> 
            </div>}
            {Object.keys(selected).length > 0 && <Option nohover placeholder value={selected.title}>
                <div>
                    <img src={selected.icon} alt={selected.alt}/>
                </div>
                <p>
                    {selected.title}
                </p>
            </Option>} 
            <span className='error'>
                <p>{props.error && props.error}</p>
            </span>          
            <AnimatePresence>
                {visible && 
                <List
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    {props.options.map((op, idx) => <Option key={idx} onClick={() => handleSelect(op)} value={op.title}>
                        <div>
                            <img src={op.icon} alt={op.alt}/>
                        </div>
                        <p>
                            {op.title}
                        </p>
                    </Option>)}
                </List>}
            </AnimatePresence>
        </SelectWrapper>
    </>
})

export default Select

const SelectWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    user-select: none;
    position: relative;

    > div {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        border: 1px solid #CACCCC;
        padding: 0 1.725em;
        
        ${({error}) => error && css`
            background: #FEF9F9 !important;
            border: 1px solid #F9566D !important;
            transition: .2s all;
        `}

        p {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #595666;
            opacity: 0.48;
        }
    }

    &:hover{
        cursor: pointer;
    }

    > label {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        margin-bottom: .5em;
    }

    span{
        position: absolute;
        bottom: -27%;
        transition: .2s all;
    }
    .error{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 10px;
        color: #CC3939;
    }     

    
`
const List = styled(motion.ul)`
    width: 100%;
    border-right: 1px solid #E6E6E6;
    border-left: 1px solid #E6E6E6;
    background: #FFFFFF;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    z-index: 5;
`
const Option = styled.li`
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;

    border-bottom: 1px solid #E6E6E6;


    > div{
        width: 4em;
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 100%;
            width: auto;
            margin: 0 auto;
        }
    }

    p{
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #595666;
    }

    &:hover{
        background-color: #01E6BEff;
        p{
            color: #fff;
            font-weight: bold;
        }
    }

    ${({nohover}) => nohover && css`pointer-events: none !important;`}
    ${({placeholder}) => placeholder && css`border: 1px solid #CACCCC !important;`}
    
`