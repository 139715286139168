import styled, { css } from 'styled-components'
import useMask from '@ryuuji3/react-mask-hook'
import { useEffect, useState } from 'react'
export default function Input(props){
    const clear = props.clear || false
    const [ value, setValue ] = useState('')
    const maskProps = useMask({
        value,
        onChange: setValue,
        mask: props.mask || '', 
        placeholder: '',
    })

    useEffect(() => {
        if(clear){
            setValue('')
        }
    }, [clear])

    return <>
        <InputWrapper error={props.error} onClick={() => props.onClick()}>
            <label>{props.label}</label>
            {!props.mask && <input name={props.name} type={props.type}/>}
            {props.mask && <input name={props.name} type={props.type} {...maskProps}/>}
            <span className='error'>
                <p>{props.error && props.error}</p>
            </span>
        </InputWrapper>
    </>
}

const InputWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    user-select: none;
    position: relative;


    > label {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        margin-bottom: .5em;
    }
    > input {
        outline: none;
        width: 100%;
        height: 48px;
        border: 1px solid #CACCCC;
        padding: 0 1.725em;

        &:focus{
            border: 1px solid #01E6BE;
        }

        ${({error}) => error && css`
            background: #FEF9F9;
            border: 1px solid #F9566D;
            transition: .2s all;

        `}
    }
    span{
        position: absolute;
        bottom: -27%;
        transition: .2s all;
    }
    .error{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 10px;
        color: #CC3939;
    }
`