import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../ui/button'
import Footer from '../../ui/footer'

export default function Hybrid(){
    const navigate = useNavigate()

    const handleClickButton = () => {
        localStorage.clear()
        navigate('budget')
    }

    return <Section id="hybrid_section">
        <HybridLetreiro src="/svgs/hybrid-letreiro.svg" alt="hybrid-letreiro"/>
        <TextWrapper>
            <h1 className='title'>Vamos construir o futuro juntos</h1>
            <p className="subtitle">Nós pensamos sempre no futuro e acreditamos que você também.</p>
            <Button onClick={() => handleClickButton()} filled>Solicitar Orçamento</Button>
        </TextWrapper>
        <Footer absolute/>
    </Section>
}


const Section = styled.section`
    max-width: 100vw;
    background-color: #0C0622;
    height: 32.875em;
    position: relative;
    

    background-image: url(/svgs/ellipse-hybrid.svg);
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;    
`

const HybridLetreiro = styled.img`
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translateX(-50%) translateY(-25%);

    @media screen and (max-width: 768px) {
        width: 120%;
    }
`

const TextWrapper = styled.div`
    width: 90%;
    max-width: 420px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    text-align: center;

    top: 17%;
    position: relative;

    .title{
        font-family: 'Space Grotesk' !important;
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1.875rem, 1.5536rem + 1.6071vw, 3rem);
        line-height: clamp(2.1875rem, 1.8482rem + 1.6964vw, 3.375rem);
        color: #fff;
        margin-bottom: .5em;
    }

    .subtitle{
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.9375rem, 0.8839rem + 0.2679vw, 1.125rem);
        line-height: clamp(1.5rem, 1.4286rem + 0.3571vw, 1.75rem);
        color: #fff;
        margin-bottom: 2em;
    }

    @media screen and (min-width: 1024px) {
        top: 13% !important;        
    }
`

