export default {
    cards: [
        {
            icon: '/svgs/iphone.svg',
            alt: 'iphone',
            title: 'Aplicativos',
            subtitle: 'Desenvolvimento de aplicativos  personalizados Android e IOS.'
        },
        {
            icon: '/svgs/laptop-computer.svg',
            alt: 'laptop-computer',
            title: 'Websites',
            subtitle: 'Diferentes métodos online que permitem a análise dos resultados em tempo real.'
        },
        {
            icon: '/svgs/computer.svg',
            alt: 'computer',
            title: 'Sistemas',
            subtitle: 'Criamos produtos customizados com dinamismo e a flexibilidade.'
        },
        {
            icon: '/svgs/airplay.svg',
            alt: 'airplay',
            title: 'Plataformas',
            subtitle: 'Solução projetada para aumentar a eficiência dos produtos internos e externos.'
        },
        {
            icon: '/svgs/outsourcing.svg',
            alt: 'outsourcing',
            title: 'Outsourcing',
            subtitle: 'Transfira tarefas, operações, trabalhos ou processos contratando nossos profissionais.'
        },
        {
            icon: '/svgs/rocket.svg',
            alt: 'rocket',
            title: 'Estratégia Digital',
            subtitle: 'Promova a sua marca ou produto na internet com nosso serviço especializado.'
        }
    ],
    services: [
        {
            icon: '/svgs/iphone.svg',
            alt: 'iphone',
            title: 'Aplicativos',
            variants: []
        },
        {
            icon: '/svgs/laptop-computer.svg',
            alt: 'laptop-computer',
            title: 'Websites',
            variants: []
        },
        {
            icon: '/svgs/computer.svg',
            alt: 'computer',
            title: 'Sistemas',
            variants: []
        },
        {
            icon: '/svgs/airplay.svg',
            alt: 'airplay',
            title: 'Plataformas',
            variants: []
        },
        {
            icon: '/svgs/outsourcing.svg',
            alt: 'outsourcing',
            title: 'Outsourcing',
            variants: []
        },
        {
            icon: '/svgs/rocket.svg',
            alt: 'rocket',
            title: 'Estratégia Digital',
            variants: []
        },
        {
            icon: '/svgs/ad-product.svg',
            alt: 'produtos',
            title: 'Produtos',
            variants: [
                {
                    icon: '/svgs/airplay.svg',
                    alt: 'plataforma de pronta resposta',
                    title: 'Plataforma de Pronta Resposta',
                }
            ]
        },
        
    ]
}