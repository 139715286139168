import styled, { css } from 'styled-components'

export default function Footer(props){
    return <FooterContainer absolute={props.absolute} relative={props.relative}>
        <FooterWrapper dark={props.dark}>
            <p>© Copyright 2022. Todos os direitos reservados.</p>
            <p className='info'>Parceiro: <span>BWS iot</span></p>
        </FooterWrapper>
    </FooterContainer>
}

const FooterContainer = styled.div`
    width: 90%;
    max-width: 1216px;
    margin: 0 auto;
    height: auto;
    user-select: none;

    ${({absolute}) => absolute && css`
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;`}
    ${({relative}) => relative && css`
                                position: relative;
                                bottom: 0;
                                left: 0;
                                right: 0;`}
`

const FooterWrapper = styled.div`
    width: 100%;
    padding: 2em 0;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;

    position: relative;

    color: #ffffff;
    ${({dark}) => dark && css`color: #191233 !important; &:before{border-top: 1px solid #000000 !important; opacity: 0.16}`}

    p:nth-child(1){
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.75rem, 0.6786rem + 0.3571vw, 1rem);
        line-height: clamp(1.5rem, 1.4643rem + 0.1786vw, 1.625rem);
        color: inherit;
        padding-top: 1em;
    }

    p:nth-child(2){
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: inherit;
        padding-bottom: 1em;

        > span {
            margin-left: 1em;
        }
    }
        
    &:before {
        content: "";
        border-top: 1px solid rgba(245, 247, 247, 0.16);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        height: 0;
        width: 100%;
    }

    @media screen and (min-width: 1024px) {

        flex-direction: row;
        justify-content: space-between;

        &:before {
            content: "";
            border-top: 1px solid rgba(245, 247, 247, 0.16);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 0;
            width: 100%;
        }

        p:nth-child(1){
            padding-top: unset;
        }

        p:nth-child(2){
            padding-bottom: unset;
        }

        
        
    }
    
    
`