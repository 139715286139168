import styled from "styled-components"
import Button from "../button"
import { useAnimation, motion } from 'framer-motion'
import { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
    

export default function Header(){
    const headerAnimation = useAnimation()

    const navigate = useNavigate()

    const handleScroll = () => {
        const yPos = window.scrollY
        headerAnimation.start('background')
        if(yPos == 0){
            headerAnimation.start('nobackground')
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, false)
        return () => {
            window.removeEventListener('scroll', handleScroll, false)
        }
    }, [])

    const handleClickLogo = () => {
        if(window.location.pathname == '/'){
            scrollToElement('home_section')
        }else{
            navigate('/')
        }
    }

    const handleClickButton = () => {
        localStorage.clear()
        navigate('budget')
    }

    const scrollToElement = (elementId) => {
        document.getElementById(elementId).scrollIntoView({block: 'start'})
    }
   
    return <Container 
        animate={headerAnimation}
        initial="nobackground"
        variants={{
            nobackground: {
                    backgroundColor: 'transparent',
                },
            background: {
                    backgroundColor: 'rgba(12, 6, 34)', 
                    backdropFilter: 'blur(5px)',
                }, 
            display: {
                display: 'block'
            },
            nodisplay: {
                display: 'none'
            }
        }}>
    <Wrapper>
        <Logo onClick={() => handleClickLogo()} src='/svgs/logotipo.svg'/>
        {window.location.pathname == '/' && <Navigation>
            <p onClick={() => scrollToElement("about_section")}>sobre nós</p>
            <p onClick={() => scrollToElement("products_section")}>produtos</p>
            <p onClick={() => scrollToElement("aws_section")}>Amazon Web services</p>
        </Navigation>}
        <Button onClick={() => handleClickButton()} outlined>solicitar orçamento</Button>
    </Wrapper>
    </Container>
}

const Logo = styled.img`
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 375px) {
        width: 30%;
    }
`

const Container = styled(motion.div)`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 11;
    transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    max-width: 100vw;
`

const Wrapper = styled.div`
    max-width: 1216px;
    margin: 0 auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    min-height: 90px;

    .logo-wrapper{
        width: unset;
        @media screen and (max-width: 375px) {
            width: 30%;            
        }
    }
`

const Navigation = styled(motion.div)`
    position: absolute;
    height: 6vh;
    bottom: -6vh;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background: rgb(12, 6, 34);
    padding: 0 5%;
    flex: 1;

    p{
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
        white-space: nowrap;
        font-size: clamp(0.75rem, 0.6897rem + 0.3448vw, 1rem);
        line-height: clamp(1.375rem, 1.3147rem + 0.3448vw, 1.625rem);
        &:hover{
            cursor: pointer;
        }
    }
    @media screen and (min-width: 768px) {
        bottom: unset;     
        position: unset;
        height: unset;
        background: unset;
        max-width: 55%;
        margin: 0 auto;
    }

    @media screen and (min-width: 1024px) {
        bottom: unset;     
        position: unset;
        height: unset;
        background: unset;
        max-width: 45%;
        margin: 0 auto;
    }
`


