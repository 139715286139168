import { useEffect } from "react";
import Budget from "../../sections/Budget";

export default function BudgetPage(){
    useEffect(() => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
        });

    }, [])
    return <>
        <Budget/>
    </>
}