import styled from 'styled-components'
import Input from '../../ui/input'
import data from '../../../mocks/about.js'
import Select from '../../ui/select'
import TextArea from '../../ui/textarea'
import Footer from '../../ui/footer'
import { useEffect, useRef } from 'react'
import Button from '../../ui/button'
import { Row } from '../../../styleds/useForAll'
import { useState } from 'react'
import discordService from '../../../services/discord'
import Map from '../../ui/map'
import { fireAlert } from '../../../services/alert'

export default function Budget(){
    const [formErrors, setFormErrors] = useState({}) 
    const [submitReqForm, setSubmitReqForm] = useState(false)
    const [serviceVariants, setServiceVariants] = useState([])
    const [clearControlledInputs, setClearControlledInputs] = useState(false)

    const selectServiceRef = useRef()
    const selectProductRef = useRef()

    const handleSubmitForm = async (e) => {
        await setSubmitReqForm(true)
        e.preventDefault()
        const form = new FormData(e.target)
        let body = {}
        if(selectProductRef.current.state().title){
            body = {
                nome: form.get('nome'),
                empresa: form.get('empresa'),
                email: form.get('email'),
                celular: form.get('celular'),
                servico: selectServiceRef.current.state().title || '',
                produto:  selectProductRef.current.state().title || '',
                mensagem: form.get('mensagem'),
            }
        }else{
            body = {
                nome: form.get('nome'),
                empresa: form.get('empresa'),
                email: form.get('email'),
                celular: form.get('celular'),
                servico: selectServiceRef.current.state().title || '',
                mensagem: form.get('mensagem'),
            }
        }
        if(validacao(body)){
            await discordService.sendMessage(body)
                .then(() => {
                    fireAlert('success', 'Orçamento enviado com sucesso.')
                    handleResetForm()
                })
                .catch(e => {
                    fireAlert('error', 'Houve um erro. Tente Novamente.')
                })
        }
        await setSubmitReqForm(false)
    }

    const handleResetForm = async () => {
        document.getElementById('btn-reset').click()
        selectServiceRef.current.clearState()
        selectProductRef.current.clearState()
        await setClearControlledInputs(true)
        setTimeout(() => {
            setClearControlledInputs(false)
        }, 200)
    }

    const validacao = (data) => {
        let validate = []
        for(let i = 0; i < Object.keys(data).length; i++){
            let key = Object.keys(data)[i]
            if(typeof data[key] == 'string'){
                if(data[key].trim().length == 0){
                    setFormErrors(prevstate => {
                        validate.push(false)
                        return {...prevstate, [key]: `O campo ${key} é obrigatório.`}
                    })
                }
            }
        }
        return validate.every(el => el == true)
    }

    const handleClick = (key) => {
        setFormErrors(prevstate => {
            return {...prevstate, [key]: false}
        })  
    }

    const handleChangeService = () => {
        setServiceVariants(data.services.filter(service => service.title == selectServiceRef.current.state().title)[0]?.variants)
    }

    //verificar se existe algum registro no localstorage com a chave 'servico' ou 'produto'
    useEffect(() => {
        let servico = localStorage.getItem('servico')
        if(servico) selectServiceRef.current.setState(JSON.parse(servico))
        let produto = localStorage.getItem('produto')
        if(servico) selectProductRef.current.setState((JSON.parse(produto)))
            
    }, [])


    return <Section>
        <Hero>
            <TitleWrapper>
                <h1>Entre em contato</h1>
                <h1>agende a sua próxima reunião</h1>
            </TitleWrapper>
        </Hero>
        <ContentWrapper>
            <Form id="budget_form" autoComplete="off" onSubmit={(e) => handleSubmitForm(e)}>
                <Row {...{gridGap:'2em', margin: "0 0 2em 0"}} flexCWS>
                    <Input 
                        error={formErrors['nome']} 
                        label="Nome" 
                        name="nome" type="text"
                        onClick={() => handleClick('nome')}/>
                    <Input 
                        error={formErrors['empresa']} 
                        label="Empresa" 
                        name="empresa" type="text"
                        onClick={() => handleClick('empresa')}/>
                </Row>
                <Row {...{gridGap:'2em', margin: "0 0 2em 0"}} flexCWS>
                    <Input 
                        error={formErrors['email']} 
                        label="E-mail" 
                        name="email" type="email"
                        onClick={() => handleClick('email')}/>
                    <Input 
                        error={formErrors['celular']} 
                        label="Celular" 
                        name="celular" type="tel"
                        mask={'(##) # ####-####'}
                        clear={clearControlledInputs}
                        onClick={() => handleClick('celular')}/>
                </Row>
                <Row {...{margin: "0 0 2em 0"}}>
                    <Select 
                        error={formErrors['servico']} 
                        ref={selectServiceRef} 
                        label="Serviço que deseja" 
                        form="budget_form" 
                        name="servico"
                        options={data.services}
                        onChange={() => handleChangeService()}
                        onClick={() => handleClick('servico')}/>
                </Row>
                <Row {...{margin: !serviceVariants?.length ? "0 0 0 0" : "0 0 2em 0"}}>
                    <Select 
                        ref={selectProductRef}
                        error={formErrors['produto']} 
                        label="Produtos" 
                        form="budget_form" 
                        name="produto"
                        hidden={!serviceVariants?.length}
                        options={serviceVariants}
                        onClick={() => handleClick('produto')}/>
                </Row> 
                <Row {...{margin: "0 0 2em 0"}}>
                    <TextArea 
                        error={formErrors['mensagem']} 
                        label="Mensagem" 
                        name="mensagem" form="budget_form"
                        onClick={() => handleClick('mensagem')} />

                </Row>
                <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button type="submit" filled disabled={submitReqForm} growWS>Enviar</Button>
                    <button id="btn-reset" hidden type="reset">reset</button>
                </Row>
            </Form>
            <MapContainer>
                <MapWrapper>
                <Map />
                    <div className="footer">
                        <TextWrapper>
                            <h1 className='title'>Barueri, SP - Brasil</h1>
                            <span>
                                <p className='content'>Rua Calçada das Acácias, 31</p>
                                <p className='content'>Andar 3 Sala 3 | CEP 06453-054</p>
                                <p className='content'>Condominio Centro Comercial Alphaville</p>
                            </span>
                        </TextWrapper>
                    </div>
                
                </MapWrapper>
            </MapContainer>
        </ContentWrapper>    
        <Footer dark absolute/>
    </Section>
} 

const Section = styled.div`
    min-height: 100vh;
    height: auto;
    position: relative;
    padding-bottom: 10em;
    max-width: 100vw;
`

const Hero = styled.div`
    height: 16.5625em;
    background-color: #0C0622;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url(/svgs/ellipse-budget.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;

    @media screen and (max-width: 768px) {
        background-image: unset;
        background-repeat: unset;
        background-size: unset;
        background-position: unset;       
    }
`
const TitleWrapper = styled.div`
    padding-top: 5em;
    width: 90%;
    margin: 0 auto;
    max-width: 694px;
    > h1{
        font-size: clamp(1.75rem, 1.4483rem + 1.7241vw, 3rem);
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        line-height: clamp(2.0625rem, 1.7457rem + 1.8103vw, 3.375rem);       
        text-align: center;
        color: #fff;
    }

    @media screen and (min-width: 1024px) {
        padding-top: 3em;   
    }
`
const ContentWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    max-width: 1216px;
    height: 100%;
    padding-top: 3em;

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 6em;
        min-height: 74vh;
    }

`
const Form = styled.form`
    height: 100%;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    padding-top: 1em;

`
const MapContainer = styled.div`
    padding-top: 2em;
    height: 74vh;
    max-height: 74vh;
`
const MapWrapper = styled.div`
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #F5F7F7;
    display: flex;
    flex-direction: column;
    .footer{
        height: 45%;
        width: 100%;
        background-color: #fff;
        align-self: flex-end;
    }


`
const TextWrapper = styled.div`
    user-select: none;
    padding: 2.5em 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    .title{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1.125rem, 1.0345rem + 0.5172vw, 1.5rem);
        line-height: clamp(1.75rem, 1.6595rem + 0.5172vw, 2.125rem);
        color: #191233;
    }

    .subtitle{
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: clamp(1rem, 0.9397rem + 0.3448vw, 1.25rem);
        line-height: clamp(1.5rem, 1.4397rem + 0.3448vw, 1.75rem);
        color: #191233;
    }

    .content{
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.875rem, 0.8147rem + 0.3448vw, 1.125rem);
        line-height: clamp(1.375rem, 1.2845rem + 0.5172vw, 1.75rem);
        color: #191233;
    }
`

