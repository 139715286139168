
import HomePage from './components/pages/home';
import BudgetPage from './components/pages/budget'
import {
  Routes,
  Route,
} from "react-router-dom";
import Layout from './components/ui/layout';

function App() {
  return <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/budget" element={<BudgetPage />} />
      </Routes>
  </Layout>;
}

export default App;
