import styled from 'styled-components'
import data from '../../../mocks/aws.js'
import Card from '../../ui/card/index.js'
export default function AWS(){
    return <Section id="aws_section">
        <TextWrapper>
            <p className="label">Amazon Web Services</p>
            <h1 className='title'>Adquira a melhor tecnologia em nuvem disponível no mercado</h1>
            <p className="subtitle">A parceria com a Amazon Web Services nos possibilita oferecer soluções confiáveis e seguras, proporcionando mais agilidade e mobilidade para sua empresa.</p>
        </TextWrapper>  
        <CardsWrapper>
            {data.cards.map(card => <Card className="card_aws" mini card={card} />)}
        </CardsWrapper>
    </Section>
}

const Section = styled.section`
    margin: 0 auto;
    width: 90%;
    max-width: 1216px;
    background-color: #fff;
    padding: 4em 0;
    @media screen and (min-width: 768px) {
        padding: 8em 0;
    }
    
    max-width: 100vw;
`

const TextWrapper = styled.div`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    text-align: center;

    .label{
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 500;
        font-size: clamp(0.875rem, 0.8036rem + 0.3571vw, 1.125rem);
        line-height: clamp(1.5rem, 1.4286rem + 0.3571vw, 1.75rem);
        color: #006655;
        margin-bottom: 2em;
        white-space: nowrap;
    }
    
    .title{
        font-family: 'Space Grotesk' !important;
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1.875rem, 1.5536rem + 1.6071vw, 3rem);
        line-height: clamp(2.1875rem, 1.8482rem + 1.6964vw, 3.375rem);
        color: #191233;
        margin-bottom: .5em;
    }
    .subtitle{
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.9375rem, 0.8839rem + 0.2679vw, 1.125rem);
        line-height: clamp(1.5rem, 1.4286rem + 0.3571vw, 1.75rem);
        color: #595666;
        margin-bottom: 3em;
    }
`

const CardsWrapper = styled.div`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row-reverse;
    grid-gap: 2em;


    @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`
