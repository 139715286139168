import Swal from 'sweetalert2';



const toast = Swal.mixin({
  position: 'top-end',
})


export function fireAlert(type, text){
    switch(type){
        case 'error':
            toast.fire({
                text: text,
                icon: 'error',
                toast: true,
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
                customClass: {
                    popup: 'sweet-custom-popup',
                    icon: 'sweet-custom-icon',
                }
            })
            break    
        case 'success':
            toast.fire({
                text: text,
                icon: 'success',
                toast: true,
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
                customClass: {
                    popup: 'sweet-custom-popup',
                    icon: 'sweet-custom-icon',
                }
            })
            break
    }
}