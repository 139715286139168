import styled from 'styled-components'
import Button from '../../ui/button'
import { useAnimation, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'


export default function Home(){
    const navigate = useNavigate()

    //animations
    const animation = useAnimation()

    const TextWrapperVariants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: [.6, .05, -.01, .9], type: "tween", stiffness: 80 }
        },
        hidden: {
            opacity: 0,
            y: '-20%',
        }
    }

    const ImageWrapperVariants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: [.6, .05, -.01, .9,], type: "tween", stiffness: 100 }
        },
        hidden: {
            opacity: 0,
            y: '20%',
        }
    }

    const CardFooterVariants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: [.6, .05, -.01, .9,], type: "tween", stiffness: 100 }
        },
        hidden: {
            opacity: 0,
            y: '40%',
        }
    }

    const InfoFooterVariants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: [.6, .05, -.01, .9,], type: "tween", stiffness: 80 }
        },
        hidden: {
            opacity: 0,
            y: '-40%',
        }
    }

    const handleClickButton = () => {
        localStorage.clear()
        navigate('budget')
    }

    return <Section id="home_section">
            <Hero>
                <TextWrapper 
                as={motion.div}
                
                animate={animation}
                initial='hidden'
                whileInView={'visible'}
                viewport={{ once: false }}
                variants={TextWrapperVariants}>
                    <h1>Você tem a</h1>
                    <h1>ideia a gente</h1>
                    <h1>desenvolve</h1>
                    <p>Nós pensamos sempre no futuro e acreditamos que você também. Junte-se a nós e vamos viver o futuro.</p>
                    <Button onClick={() => handleClickButton()} filled>Solicitar Orçamento</Button>
                </TextWrapper>
                <WrapperImages 
                    as={motion.div}
                    whileInView={'visible'}
                    viewport={{ once: false }}
                    animate={animation}
                    initial='hidden'
                    variants={ImageWrapperVariants}>
                    <StyledWoman src="/svgs/woman.png" />
                    <StyledRectangles src="/svgs/rectangles.svg" />
                </WrapperImages>
            </Hero>
            <Footer>
                <motion.div class="info"
                    whileInView={'visible'}
                    viewport={{ once: false }}
                    animate={animation}
                    initial='hidden'
                    variants={InfoFooterVariants}>
                    <p>
                        Utilizamos tecnologias de última geração para transformar
                        negócios e oferecer o melhor aos nossos clientes.
                    </p>
                </motion.div>
                <CardWrapper
                    whileInView={'visible'}
                    viewport={{ once: false }}
                    as={motion.div}
                    animate={animation}
                    initial='hidden'
                    variants={CardFooterVariants}>
                    <Card>
                        <img src="/svgs/file-code.svg"/>
                        <div className="content_wrapper">
                            <p className="content">Desenvolvimento</p>
                            <p className="content">de produtos</p>
                        </div>
                    </Card>
                    <Card>
                        <img src="/svgs/cloud-storage.svg"/>
                        <div className="content_wrapper">
                            <p className="content">A melhor tecnologia</p>
                            <p className='content'>em nuvem</p>
                        </div>
                    </Card>
                </CardWrapper>
            </Footer>
    </Section>
}

const Section = styled.section`
    max-width: 100vw;

    background-color: #0C0622;
    min-height: 90vh;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background-image: url(/svgs/ellipse-home.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;

    @media screen and (max-width: 768px) {
        background-image: unset;
        background-repeat: unset;
        background-size: unset;
        background-position: unset;       
    }
`


const StyledRectangles = styled.img`
    bottom: 0;
    width: 100%;
`

const StyledWoman = styled.img`
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 65%;
`

const Hero = styled.div`
    display: grid;
    grid-template-columns: repeat(2, .5fr);
    align-items: center;
    width: 90%;
    max-width: 1216px;
    margin: 0 auto;
    padding-top: 10em;
    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr !important;
        flex-flow: column !important;
    }
`

const WrapperImages = styled.div`
    display: flex; 
    align-items: flex-end; 
    position: relative;
    flex-shrink: .5;
    width: 100%;
    @media screen and (max-width: 1024px) {
        padding-top: 5em !important;
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 1;

    h1 {
        font-family: 'Space Grotesk' !important;
        font-style: normal;
        font-weight: 700;
        font-size: clamp(2.375rem, 1.9828rem + 2.2414vw, 4rem);
        line-height: clamp(2.625rem, 2.3233rem + 1.7241vw, 3.875rem);
        color: #fff;
        letter-spacing: 0.02em;
    }

    > h1:nth-child(3){
        margin-bottom: .5em;
    }

    p {
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.02em;
        color: #fff;
        font-size: clamp(0.8125rem, 0.7371rem + 0.431vw, 1.125rem);
        line-height: clamp(1.375rem, 1.2845rem + 0.5172vw, 1.75rem);
        margin-bottom: 2rem;
        width: 80%;
        @media screen and (max-width: 768px) {
            width: 90%;        
        }
        @media screen and (max-width: 420px) {
            width: 100%;        
        }
    }

    button{
        flex-grow: 0;
    }

    @media screen and (max-width: 768px) {
        width: 100%;        
    }
`

const Footer = styled.div`
    width: 90%;
    max-width: 1216px;
    min-height: 9.75em;
    height: auto;
    position: relative;
    padding: 2em 0;

    .info {
        color: #fff;
        font-size: clamp(0.875rem, 0.8448rem + 0.1724vw, 1rem);
        line-height: clamp(1.375rem, 1.3147rem + 0.3448vw, 1.625rem);
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        justify-self: flex-start;
        width: 100%;      
        margin-bottom: 4em;
        position: relative;
        &:before {
            content: "";
            border-top: 1px solid rgba(245, 247, 247, 0.16);
            position: absolute;
            bottom: -2em;
            transform: translateX(-50%);
            left: 50%;
            height: 0;
            width: 100%;
        }

    }

    @media screen and (min-width: 1024px) {
        display: grid !important;
        grid-template-columns: repeat(2, .5fr) !important;
        place-items: center !important;
        justify-content: space-between !important;
        .info {
            margin-bottom: unset !important;
            width: 80% !important;

            &:before {
                display: none;
            }
        }

        &:after {
            content: "";
            border-right: 1px solid rgba(245, 247, 247, 0.16);
            position: absolute;
            left: 43%;
            top: 0;
            bottom: 0;
        }  

        &:before {
            content: "";
            border-top: 1px solid rgba(245, 247, 247, 0.16);
            position: absolute;
            top: 0%;
            transform: translateX(-50%);
            left: 50%;
            height: 0;
            width: 100%;
        }  
    }

    @media screen and (min-width: 1024px) and (max-width: 1216px){
        grid-template-columns: .8fr 1fr !important;
        
        &:after {
            display: none;
        } 
    }    

  
`

const CardWrapper = styled.div`
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    position: relative;
    @media screen and (min-width: 1024px) {
        width: 100% !important;
        align-items: center !important;
    }
`
const Card = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;

    .content_wrapper{
        padding-left: 2%;
    }


    .content {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: clamp(0.875rem, 0.7845rem + 0.5172vw, 1.25rem);
        line-height: clamp(1.375rem, 1.2845rem + 0.5172vw, 1.75rem);  
        color: #fff;
        white-space: nowrap;
    }

    > img {
        margin-bottom: 1em;
    }  


    @media screen and (min-width: 768px){
        flex-direction: row !important;
        align-items: center !important;
        > img {
            margin-bottom: unset !important;
        }   
        .content_wrapper{
            padding-left: 8%;
        }
    }
`