import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useWindowSize from '../../../hooks/useWindowSize'
import Button from '../../ui/button'
import dataAbout from '../../../mocks/about'

export default function About(){
    const { width, height } = useWindowSize()
    const navigate = useNavigate()

    const handleClickButton = () => {
        let service = dataAbout.services.filter(el => el.title == 'Produtos')[0]
        localStorage.setItem('servico', JSON.stringify(service))
        localStorage.setItem('produto', JSON.stringify(service.variants[0]))
        navigate('budget')
    }

    return <Section id="products_section">    
        <BackgroundWhite>
            <InfoWrapper>
                    <p className="label">Produtos</p>
                    <span className='title-wrapper'>
                        <h1 className='title'>Conheça o nosso</h1>
                        <h1 className='title'>sistema de pronta</h1>
                        <h1 className='title'>resposta</h1>
                    </span>
                    <p className="subtitle">Adaptado e personalizado para ajudar sua empresa e agentes de segurança a trabalharem melhor, mais rápido e com mais eficiencia.</p>
                    {width >= 1024 ? 
                        <Footer>
                            <Button onClick={() => handleClickButton()} filled>Solicitar Orçamento</Button>
                            {/* <div className="arrow-group">
                                <img src="svgs/arrow-simple-left.svg" alt="arrow icon left"/>
                                <img src="svgs/arrow-simple-right.svg" alt="arrow icon right"/>
                            </div> */}
                        </Footer> 
                    : null}
            </InfoWrapper>
        </BackgroundWhite>
        <BackgroundImage>
            <img src="/svgs/devices.svg" alt="devices"/>
        </BackgroundImage>
        {width < 1024 ? 
            <Footer>
                <Button onClick={() => handleClickButton()} filled>Solicitar Orçamento</Button>
                {/* <div className="arrow-group">
                    <img src="svgs/arrow-simple-left.svg" alt="arrow icon left"/>
                    <img src="svgs/arrow-simple-right.svg" alt="arrow icon right"/>
                </div> */}
            </Footer> 
        : null}
    </Section>
}

const Section = styled.section`
    padding-top: 4em;
    max-width: 100vw;
    display: grid;
    grid-template-rows: 1fr .8fr .2fr;
    grid-gap: 1em;
    
    @media screen and (min-width: 1024px) {
        grid-template-rows: unset !important;
        grid-template-columns: 1fr 1fr !important;
        height: 42em !important;
        grid-gap: 2em !important;
    }

    @media screen and (min-width: 768px) {
        grid-gap: 3em !important;
        padding-top: 8em;
    }
    
`


const Background = styled.div`
    width: 100%;
`

const BackgroundWhite = styled(Background)`
    padding-left: calc(100vw * 0.05) !important;

    @media screen and (min-width: 1440px){
        position: relative;
        margin: 0 auto;
        padding-left: calc((100vw - 1216px)/2) !important;
    }

`

const BackgroundImage = styled(Background)`
    position: relative;
    background-color: #0C0622;
    height: 65%;
    align-self: center;
   
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(/svgs/ellipse-home.svg);
        background-repeat: no-repeat;
        background-size: cover;
        transform: rotate(180deg);
    }

    > img {
        height: 120%;
        z-index: 2;
        margin: 0 auto;
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);


        @media screen and (min-width: 1440px){
            right: calc((100vw - 1216px)/2) !important;
            width: 90%;
            left: unset;
        }

        @media screen and (min-width: 1216px) {
            right: calc(100vw * 0.05);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: unset;
            z-index: unset;
            margin: unset;
            left: unset;
            height: unset;
            width: unset;
        }

        @media screen and (max-width: 1216px) {
            right: calc(100vw * 0.05);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: unset;
            z-index: unset;
            margin: unset;
            left: unset;
            width: 110%;
        }

        @media screen and (min-width: 768px) {
            margin: unset;        
        }

        @media screen and (max-width: 1024px) {
            z-index: 2;
            margin: 0 auto;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            width: unset;
        }


    }

    @media screen and (min-width: 768px) {
        height: 100%;
    }

`

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 90%;
    margin: 0 auto;

    .arrow-group{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            width: 45%;
            height: 100%;
        }
    }

    @media screen and (min-width: 1024px) {
        width: 90%;
        margin: unset;
        justify-content: space-between;
    }
`

const InfoWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    .label{
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 500;
        font-size: clamp(0.875rem, 0.8036rem + 0.3571vw, 1.125rem);
        line-height: clamp(1.5rem, 1.4286rem + 0.3571vw, 1.75rem);
        color: #006655;
        margin-bottom: 1em;
    }
    .title{
        font-family: 'Space Grotesk' !important;
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1.875rem, 1.5536rem + 1.6071vw, 3rem);
        line-height: clamp(2.1875rem, 1.8482rem + 1.6964vw, 3.375rem);
        color: #191233;
    }
    .subtitle{
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.9375rem, 0.8839rem + 0.2679vw, 1.125rem);
        line-height: clamp(1.5rem, 1.4286rem + 0.3571vw, 1.75rem);
        color: #595666;
    }

    .title-wrapper{
        margin-bottom: 1em;
    }

    @media screen and (min-width: 1216px){
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 72%;
        height: 70%;
        margin: unset;

        .label{
            margin-bottom: unset;
        }

        .title-wrapper{
            margin-bottom: unset;
        }
    }
    @media screen and (min-width: 1024px){
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 70%;
        margin: unset;
        width: 80%;

        .label{
            margin-bottom: unset;
        }

        .title-wrapper{
            margin-bottom: unset;
        }
    }
`
