import styled from 'styled-components'
import data from '../../../mocks/about.js'
import Card from '../../ui/card/index.js'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '../../../hooks/useWindowSize'
export default function About(){
    //navigation
    const navigate = useNavigate()
    
    const CardWrapperVariants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: .6, ease: [.6, .05, -.01, .9], type: "tween", stiffness: 80 }
        },
        hidden: {
            opacity: 0,
            y: '30%',
        }
    }

    const HeroVariants = {
        visible: {
            opacity: 1,
            x: 0,
            transition: { duration: .6, ease: [.6, .05, -.01, .9], type: "tween", stiffness: 80, staggerChildren: .2 }
        },
        hidden: {
            opacity: 0,
            x: '30%',
        }
    }

    const HeroChildVariants = {
        visible: {
            opacity: 1,
            x: 0,
            transition: { duration: .6, ease: [.6, .05, -.01, .9], type: "tween", stiffness: 80 }
        },
        hidden: {
            opacity: 0,
            x: '30%',
        }
    }

    const handleClickCard = (data) => {
        localStorage.setItem('servico', JSON.stringify(data))
        navigate('budget')
    }

    return <Section id="about_section">
        <Hero 
            as={motion.div}
            initial='hidden'
            variants={HeroVariants}
            whileInView={'visible'}
            viewport={{ once: false }}
        >
            <VideoWrapper>
                <video 
                    autoPlay 
                    muted 
                    loop
                    controls={true}
                >
                    <source src={'https://firebasestorage.googleapis.com/v0/b/hybridserver-48c11.appspot.com/o/hybrid%20video.mp4?alt=media&token=f4ff4ecb-a610-40ac-b939-1b910d77a736'} type="video/mp4"/>
                </video>
            </VideoWrapper>
            <TextWrapper>
                <span>
                    <p className="label">Quem somos</p>
                    <TitleWrapper 
                        as={motion.div}
                        initial='hidden'
                        variants={HeroChildVariants}
                        whileInView={'visible'}
                        viewport={{ once: false }}>
                            <h1 className='title'>Empresa de</h1>
                            <h1 className='title'>Engenharia de</h1>
                            <h1 className='title'>software</h1>
                    </TitleWrapper>
                    <p className="subtitle">Especializada no desenvolvimento de aplicativos, sistemas, softwares e websites.</p>
                </span>
                <TextFooter>
                    <IconQuote />
                    <p className="info">O futuro depende das ideias e as ideias dependem de você.</p>
                </TextFooter>
            </TextWrapper>
        
        </Hero>
        <CardsWrapper id="cards_wrapper"
            whileInView={'visible'}
            viewport={{ once: false }}
            as={motion.div}
            initial='hidden'
            variants={CardWrapperVariants}>
            {data.cards.map(card => <Card card={card} onClick={() => handleClickCard(card)}/>)}
        </CardsWrapper>
        

    </Section>
}

const Section = styled.section`
    z-index: 10;
    background-color: #fff;
    height: auto;
    padding-top: 4em;
    @media screen and (min-width: 768px) {
        padding-top: 8em;
    }
    min-height: 90vh;
    
    max-width: 100vw;
`
const Hero = styled.div`
    max-width: 1216px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    @media screen and (min-width: 1024px){
        width: 90%;
        display: grid !important;
        grid-template-columns: 1fr .6fr !important;
    }
`
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 5%;


    .label{
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 500;
        font-size: clamp(0.875rem, 0.8036rem + 0.3571vw, 1.125rem);
        line-height: clamp(1.5rem, 1.4286rem + 0.3571vw, 1.75rem);
        color: #006655;
        margin-bottom: 1em;
    }
    .title{
        font-family: 'Space Grotesk' !important;
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1.875rem, 1.5536rem + 1.6071vw, 3rem);
        line-height: clamp(2.1875rem, 1.8482rem + 1.6964vw, 3.375rem);
        color: #191233;
    }
    .title:nth-child(3){
        margin-bottom: .5em;
    }
    .subtitle{
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.9375rem, 0.8839rem + 0.2679vw, 1.125rem);
        line-height: clamp(1.5rem, 1.4286rem + 0.3571vw, 1.75rem);
        text-align: left;
        color: #595666;
        margin-bottom: 3em;
    }
    .info{
        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 400;
        font-size: clamp(0.9375rem, 0.8839rem + 0.2679vw, 1.125rem);
        line-height: clamp(1.5rem, 1.4643rem + 0.1786vw, 1.625rem);
        color: #595666;
    }

    @media screen and (min-width: 1024px) {
        margin: unset;
        margin-left: 15% !important;
    }


`
const TextFooter = styled.div`
    display: flex; 
    align-items: center;
    position: absolute;
    bottom: -6em;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        bottom: unset !important;
        position: unset !important;
        width: 100% !important;
        margin-bottom: 1em !important;
    }
`
const IconQuote = styled.div`
    background: rgba(1, 230, 190, 0.08);
    background-image: url('/svgs/quote.svg');
    width: 56px;
    height: 56px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    margin-right: 2em;
    flex-shrink: 0;
`
const TitleWrapper = styled.div``

const StyledSetup = styled.img`
    width: 100%;
    height: auto;
    @media screen and (min-width: 1024px) {
        width: 95% !important;
    }
`
const CardsWrapper = styled.div`
    padding-top: 8em;
    margin: 0 auto;
    max-width: 1216px;
    width: 100%;
    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        grid-gap: 0px;
    }
`

const VideoWrapper = styled.div`
    video {
        width: 100%;
    }

    .video-react-control-bar{
        display: none;
    }

    .video-react-button {
        display: none;
    }
`