import axios from 'axios'

// export const baseURL = "http://localhost:3333";
export const baseURL = "https://hybridbudget.herokuapp.com";

const appAccessToken = 'Xq72lCNUNu6A182UXq7C2l5W019P9jvsV3XD6Cq72lU'

const baseEndpoint = "/api/budget";

export default () => {
  return axios.create({
    baseURL: baseURL + baseEndpoint, 
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'x-access-token': appAccessToken
    }
  })
  
}

