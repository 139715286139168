export default {
    cards: [
        {
            icon: '/svgs/infraestrutura.svg',
            alt: 'Infraestrutura',
            title: 'Infraestrutura',
        },
        {
            icon: '/svgs/certificacoes.svg',
            alt: 'Certificações',
            title: 'Certificações',
        },
        {
            icon: '/svgs/switch.svg',
            alt: 'Auxilio em revenda',
            title: 'Auxilio em revenda',
        },
    ]
}
