import styled, { css } from 'styled-components'
export default function Button(props){
    return <StyledButton {...props}>{props.children}</StyledButton>
}

const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: clamp(0.75rem, 0.6897rem + 0.3448vw, 1rem);
    font-weight: 700;
    line-height: 28px;
    min-width: 11em;
    padding: .5em 2em;
    user-select: none;
    transition: .1s cubic-bezier(0.075, 0.82, 0.165, 1);
    
    ${props => props.outlined && css`
        border: 1px solid rgba(1, 230, 190, 0.24) !important;
        font-style: normal !important;
        color: #FFFFFF !important;
        @media screen and (max-width: 375px) {
            padding: .2em 1em !important;
            
        }
        &:hover{
            cursor: pointer !important;
        }

    `}

    ${props => props.filled && css`
        font-style: normal !important;
        color: #0C0622 !important;
        background: #01E6BE !important;
        @media screen and (max-width: 375px) {
            padding: .2em 1em !important;
            
        }
        &:hover{
            cursor: pointer !important;
            background: #01CCA9 !important;
            color: #191233 !important;
        }

        
    `}

    ${({growWS}) => growWS && css`
        @media screen and (max-width: 420px) {
            flex: 1 !important;
        }
    `}

    &:disabled {
        background-color: rgba(0,0,0,0.16) !important;
        color: #ccc;
    }


`